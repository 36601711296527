import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { DownloadTableExcel } from "react-export-table-to-excel";
import "./demo.css";
import { MDBBtn, MDBDataTable } from "mdbreact";
import { v4 as uuidv4 } from "uuid";
import config from "../../config";
import { CSVLink } from "react-csv";
import DownloadCSVButton from "./downloadCSV";
const Test = () => {
  const tableRef = useRef(null);
  const [formData, setFormData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [download, setDownload] = useState(false);
  const UserDetails = sessionStorage.getItem("LoginUser");
  const UserDetailsData = JSON.parse(UserDetails);
  // Function to fetch data from the server
  const fetchData = async () => {
    try {
      const response = await axios.get(`${config.baseUrl}/householdData`);
      if (Array.isArray(response.data.recordset)) {
        const formattedData = response.data.recordset
          .map((item) => {
            const parsedFormData = item.FORM_DATA
              ? JSON.parse(item.FORM_DATA)
              : null;

            return {
              ...item,
              FORM_DATA: parsedFormData,
              userId: uuidv4(),
              interviewerId: uuidv4(),
            };
          })
          .filter((item) => item.FORM_DATA);

        const filteredData = formattedData.filter((item) => {
          if (UserDetailsData.ROLE === "0") {
            // For ROLE "0", return all items
            // console.log("FORM_DATA (ROLE 0):", item);
            return true;
          } else {
            // For other roles, filter based on city match
            if (item.FORM_DATA.generalInformation) {
              const generalInformation = item.FORM_DATA
                ? JSON.parse(item.FORM_DATA.generalInformation)
                : null;
              if (generalInformation && generalInformation.districtName) {
                if (UserDetailsData.CITY === generalInformation.districtName) {
                  // console.log("FORM_DATA (City Match):", item);
                  return true;
                }
              }
            }
          }

          // If item does not match the conditions, it will not be included in filteredData
          return false;
        });

        console.log("Filtered Data:", filteredData);
        setFormData(filteredData);
      } else {
        console.error("Invalid data format:", response.data);
      }
    } catch (error) {
      console.error("Error fetching household data:", error);
    }
  };
  const surveyStatusMap = {
    "पूरा हुआ": "Completed",
    "పూర్తయింది": "Completed",
    "Completed": "Completed",
    0: "Completed",
    "పాల్గొనేవారు ఇంట్లో లేరు": "Participant not present at home",
    "प्रतिभागी घर पर उपस्थित नहीं है": "Participant not present at home",
    "Participant not present at home": "Participant not present at home",
    1: "Participant not present at home",
    "साक्षात्कारकर्ता मिलने में असमर्थ": "Interviewer unable to visit",
    "ఇంటర్వ్యూయర్ సందర్శించలేకపోయారు": "Interviewer unable to visit",
    "Interviewer unable to visit": "Interviewer unable to visit",
    2: "Interviewer unable to visit",
    "सार्वजनिक अवकाश": "Public holiday",
    "పబ్లిక్ హాలిడే": "Public holiday",
    "Public holiday": "Public holiday",
    3: "Public holiday",
    "कोई अन्य कारण": "Any other reason",
    "ఏదైనా ఇతర కారణం": "Any other reason",
    "Any other reason": "Any other reason",
    4: "Any other reason",
  };

  const facility = {
    "सरकारी": "Government",
    "గవర్నమెంట్": "Government",
    "Government": "Government",
    "Private": "Private",
    "निजी": "Private",
    "ప్రైవేట్": "Private",
  }

  const consult = {
    0: "Government Doctor",
    1: "Private Doctor",
    2: "Community Health Officer (CHO)",
    3: "ANM",
    4: "ASHA",
    5: "Nurse",
    6: "RMP(Unqualified doctor)",
    7: "Pharmacy/Chemist Shop",
    8: "Others"
  }

  const drugtype = {
    0: "Antipyretic/fever lowering medicine",
    1: "Antibiotics",
    2: "Analgesic/Pain killer",
    3: "Steroids",
    4: "Others",
    5: "Don't know",
  }

  const complaint = {
    0: "Heart Disease",
    1: "Asthma",
    2: "Chronic Liver Disease",
    3: "Diabetes",
    4: "Neuro Muscular Dysfunction",
    5: "Chronic Kidney",
    6: "Chronic Haematological",
    7: "Immune Compromised",
    8: "Tuberculosis",
    9: "COPD",
    10: "None of the above",
    11: "Don't know"
  }

  const refuse = {
    "I will not be available on the time of sample collection": "I will not be available on the time of sample collection",
    "నమూనా సేకరణ సమయంలో నేను అందుబాటులో ఉండను": "I will not be available on the time of sample collection",
    "मैं नमूना संग्रह सैंपल देने के समय उपलब्ध नहीं रहूंगा": "I will not be available on the time of sample collection",
    "मैं स्वैब सैंपल देने से झिझक रहा हूं": "I am hesitant to provide swab sample",
    "నేను నమూనాను అందించడానికి సంకోచిస్తున్నాను": "I am hesitant to provide swab sample",
    "I am hesitant to provide swab sample": "I am hesitant to provide swab sample",
    "అసౌకర్యం వల్ల నేను భయపడుతున్నాను": "I am afraid of the discomfort",
    "मुझे सैंपल देने में होने वाली असुविधा से डर लगता है ": "I am afraid of the discomfort",
    "I am afraid of the discomfort ": "I am afraid of the discomfort",
    "अन्य (कृपया निर्दिष्ट करें)": "Others(Please Specify)",
    "Others(Please Specify)": "Others(Please Specify)",
    "ఇతర కారణాలు(దయచేసి పేర్కొనండి)": "Others(Please Specify)"
  }

  // Fetch data once when component mounts
  useEffect(() => {
    if (
      UserDetailsData.ROLE === "0" ||
      UserDetailsData.ROLE === "10" ||
      UserDetailsData.ROLE === "20"
    ) {
      setDownload(true);
    }
    fetchData();
  }, []);

  // Function to handle filtering by date range
  // const handleFilterByDateRange = async () => {
  //   try {
  //     const response = await axios.get(`${config.baseUrl}/householdData`);
  //     if (Array.isArray(response.data.recordset)) {
  //       const formattedData = response.data.recordset
  //         .map((item) => ({
  //           ...item,
  //           FORM_DATA: item.FORM_DATA ? JSON.parse(item.FORM_DATA) : null,
  //           userId: uuidv4(),
  //           interviewerId: uuidv4(),
  //         }))
  //         .filter((item) => item.FORM_DATA);

  //       // Filter the data based on date range
  //       const filteredData = formattedData.filter((item) => {
  //         const itemDate = new Date(item.STATUS_DATE);
  //         return itemDate >= startDate && itemDate <= endDate;
  //       });

  //       // Update formData state with filtered data
  //       console.log("filterData based on date ",filteredData)
  //       setFormData(filteredData);
  //     } else {
  //       console.error("Invalid data format:", response.data);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching or filtering household data:", error);
  //   }
  // };

  // const handleFilterByDateRange = async () => {
  //   try {
  //     const response = await axios.get(`${config.baseUrl}/householdData`);
  //     if (Array.isArray(response.data.recordset)) {
  //       const formattedData = response.data.recordset
  //         .map((item) => ({
  //           ...item,
  //           FORM_DATA: item.FORM_DATA ? JSON.parse(item.FORM_DATA) : null,
  //           userId: uuidv4(),
  //           interviewerId: uuidv4(),
  //         }))
  //         .filter((item) => item.FORM_DATA);

  //       // Filter the data based on date range
  //       const filteredData = formattedData.filter((item) => {
  //         const itemDate = new Date(item.STATUS_DATE);
  //         const isSameDate = startDate.toDateString() === endDate.toDateString();
  //         return isSameDate
  //           ? itemDate.toDateString() === startDate.toDateString()
  //           : itemDate >= startDate && itemDate <= endDate;
  //       });

  //       // Update formData state with filtered data
  //       console.log("filterData based on date ", filteredData);
  //       setFormData(filteredData);
  //     } else {
  //       console.error("Invalid data format:", response.data);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching or filtering household data:", error);
  //   }
  // };

  const handleFilterByDateRange = async () => {
    try {
      const response = await axios.get(`${config.baseUrl}/householdData`);
      if (Array.isArray(response.data.recordset)) {
        const formattedData = response.data.recordset
          .map((item) => ({
            ...item,
            FORM_DATA: item.FORM_DATA ? JSON.parse(item.FORM_DATA) : null,
            userId: uuidv4(),
            interviewerId: uuidv4(),
          }))
          .filter((item) => item.FORM_DATA);
        console.log(formattedData, "formattedData..");
        // Add one day to the end date to make it inclusive
        const inclusiveEndDate = new Date(endDate);
        inclusiveEndDate.setDate(inclusiveEndDate.getDate() + 1);

        // Filter the data based on date range
        const filteredData = formattedData.filter((item) => {
          const itemDate = new Date(item.CREATED_DATE);
          const isSameDate =
            startDate.toDateString() === endDate.toDateString();
          return isSameDate
            ? itemDate.toDateString() === startDate.toDateString()
            : itemDate >= startDate && itemDate < inclusiveEndDate;
        });

        // Update formData state with filtered data
        console.log("Filtered data based on date range:", filteredData);
        setFormData(filteredData);
      } else {
        console.error("Invalid data format:", response.data);
      }
    } catch (error) {
      console.error("Error fetching or filtering household data:", error);
    }
  };

  // Validation for enabling Apply Date Range button
  const isApplyButtonDisabled = !startDate || !endDate;

  const filler = "999";
  const filler2 = "777";

  const myFunction = (FORM_DATA, HealthType, itemsName, joinitem) => {
    // console.log(itemsName)
    if (FORM_DATA !== null && FORM_DATA.houseHoldMemberModel !== undefined) {
      return Array.isArray(FORM_DATA.houseHoldMemberModel)
        ? FORM_DATA.houseHoldMemberModel
          .map((member) => {
            if (member !== undefined && member[HealthType] !== undefined) {
              // console.log('hie rev', member);
              const currentHealth = JSON.parse(member[HealthType]);
              console.log('hie rev', currentHealth);
              return currentHealth &&
                currentHealth[itemsName] != null &&
                currentHealth[itemsName] != ""
                ? currentHealth[itemsName]
                : filler2; // Assuming filler2 is defined somewhere
            } else {
              return filler;
            }
          })
          .join(joinitem === "," ? "," : "\n")
        : filler;
    }
  };

  const myFunction1 = (FORM_DATA, HealthType, itemsName, joinitem) => {
    if (FORM_DATA !== null && FORM_DATA.houseHoldMemberModel !== undefined) {
      return Array.isArray(FORM_DATA.houseHoldMemberModel)
        ? FORM_DATA.houseHoldMemberModel
          .map((member) => {
            if (member !== undefined && member[HealthType] !== undefined) {
              const currentHealth = JSON.parse(member[HealthType]);
              return currentHealth &&
                currentHealth[itemsName] !== undefined &&
                currentHealth[itemsName] !== null
                ? currentHealth[itemsName]
                : filler2; // Assuming filler2 is defined somewhere
            } else {
              return filler;
            }
          })
          .join(joinitem === "," ? "," : "\n")
        : filler;
    }
  };


  const myFunctionlistnubertodata = (FORM_DATA, HealthType, itemsName, consult, joinitem) => {
    // Check if FORM_DATA is valid and houseHoldMemberModel exists
    if (FORM_DATA !== null && FORM_DATA.houseHoldMemberModel !== undefined) {
      // Verify if houseHoldMemberModel is an array
      return Array.isArray(FORM_DATA.houseHoldMemberModel)
        ? FORM_DATA.houseHoldMemberModel
          .map((member) => {
            // Check if the member and the required HealthType exist
            if (member !== undefined && member[HealthType] !== undefined) {
              const currentHealth = JSON.parse(member[HealthType]); // Parse JSON data

              // Validate currentHealth and the required itemsName
              if (
                currentHealth &&
                currentHealth[itemsName] != null &&
                currentHealth[itemsName] !== ""
              ) {
                // Transform object entries into an array of strings based on `consult` mapping
                if (currentHealth[itemsName] !== undefined) {
                  const entries = Object.entries(currentHealth[itemsName]).map(
                    ([key, value]) => consult[value] || `Unknown (${value})` // Assuming `consult` is defined
                  );
                  return entries.join(", ");
                }
              } else {
                return filler2; // Assuming filler2 is defined
              }
            } else {
              return filler; // Assuming filler is defined
            }
          })
          .join(joinitem === "," ? "," : "\n")
        : filler; // Return filler if houseHoldMemberModel is not an array
    }
  };


  const myFunctionlisttexttodata = (FORM_DATA, HealthType, itemsName, consult, joinitem) => {
    // Check if FORM_DATA is valid and houseHoldMemberModel exists
    if (FORM_DATA !== null && FORM_DATA.houseHoldMemberModel !== undefined) {
      // Verify if houseHoldMemberModel is an array
      return Array.isArray(FORM_DATA.houseHoldMemberModel)
        ? FORM_DATA.houseHoldMemberModel
          .map((member) => {
            // Check if the member and the required HealthType exist
            if (member !== undefined && member[HealthType] !== undefined) {
              const currentHealth = JSON.parse(member[HealthType]); // Parse JSON data

              // Validate currentHealth and the required itemsName
              if (
                currentHealth &&
                currentHealth[itemsName] != null &&
                currentHealth[itemsName] !== ""
              ) {
                // Transform object entries into an array of strings based on `consult` mapping
                if (currentHealth[itemsName] !== undefined) {
                  const entries = Array.isArray(currentHealth[itemsName])
                    ? currentHealth[itemsName].map(value => consult[value] || `Unknown (${value})`)
                    : [consult[currentHealth[itemsName]] || `Unknown (${currentHealth[itemsName]})`];

                  return entries.join(joinitem === "," ? "," : "\n");

                  return entries.join(", ");
                }
              } else {
                return filler2; // Assuming filler2 is defined
              }
            } else {
              return filler; // Assuming filler is defined
            }
          })
          .join(joinitem === "," ? "," : "\n")
        : filler; // Return filler if houseHoldMemberModel is not an array
    }
  };



  // Define columns
  // Define columns
  const columns = [
    {
      label: "User ID",
      field: "userId",
      sort: "asc",
    },
    {
      label: "interviewr_name",
      field: "interviewerName",
      sort: "asc",
    },
    {
      label: "interviewr_id",
      field: "interviewerId",
      sort: "asc",
    },
    {
      label: "ud_no",
      field: "udNumber",
      sort: "asc",
    },
    {
      label: "PSU",
      field: "psu",
      sort: "asc",
    },
    {
      label: "district",
      field: "district",
      sort: "asc",
    },
    {
      label: "HHID",
      field: "hidNumber",
      sort: "asc",
    },
    {
      label: "Survey_status",
      field: "surveystatus",
      sort: "asc",
    },
    {
      label: "Participant Consent",
      field: "participantConsent",
      sort: "asc",
    },
    {
      label: "residence",
      field: "sector",
      sort: "asc",
    },
    {
      label: "Pid",
      field: "pid",
      sort: "asc",
    },
    {
      label: "name_of_the_town_village",
      field: "city",
      sort: "asc",
    },

    {
      label: "number_of_household_member",
      field: "householdMembers",
      sort: "asc",
    },
    {
      label: "CHC Name",
      field: "chcName",
      sort: "asc",
    },
    {
      label: "PHC Name",
      field: "phcName",
      sort: "asc",
    },
    {
      label: "Health Center Name",
      field: "healthCenterName",
      sort: "asc",
    },
    {
      label: "Date",
      field: "date",
      sort: "asc",
    },
    {
      label: "Symptoms",
      field: "symptoms",
      sort: "asc",
    },
    {
      label: "noSymptoms",
      field: "nosymtomsbutton",
      sort: "asc",
    },
    {
      label: "Symptoms Members",
      field: "symptomsMembers",
      sort: "asc",
    },
    {
      label: "name",
      field: "patientName",
      sort: "asc",
    },
    {
      label: "Age",
      field: "patientAge",
      sort: "asc",
    },
    {
      label: "Gender",
      field: "gender",
      sort: "asc",
    },
    {
      label: "marital_status",
      field: "maritalStatus",
      sort: "asc",
    },
    {
      label: "specify_marital_status_other",
      field: "othermarital",
      sort: "asc",
    },
    {
      label: "educational_status",
      field: "educationalStatus",
      sort: "asc",
    },
    {
      label: "specify_education_other",
      field: "othereducation",
      sort: "asc",
    },
    {
      label: "occupational_status",
      field: "occupationStatus",
      sort: "asc",
    },
    {
      label: "specify_occupational_other",
      field: "otheroccupation",
      sort: "asc",
    },
    {
      label: "do_you_smoke_tobacco_or_co_v1",
      field: "tobaccoStatus",
      sort: "asc",
    },
    {
      label: "do_you_consume_alcohol_v1",
      field: "alcoholStatus",
      sort: "asc",
    },
    {
      label: "measurement_of_waist_in_cm_v1",
      field: "patientWaistInCm",
      sort: "asc",
    },
    {
      label: "measurement_of_height_v1",
      field: "patientHeightInCm",
      sort: "asc",
    },
    {
      label: "Body_weight_v1",
      field: "patientWeightInKg",
      sort: "asc",
    },
    {
      label: "clinical_body_temperature_v1",
      field: "patientTemperatureInC",
      sort: "asc",
    },
    {
      label: "fever_v1",
      field: "isFever",
      sort: "asc",
    },
    {
      label: "duration_fever",
      field: "feverDays",
      sort: "asc",
    },
    {
      label: "myalgia_v1",
      field: "isMyalgia",
      sort: "asc",
    },
    {
      label: "duration_myalgia",
      field: "myalgiaDays",
      sort: "asc",
    },
    {
      label: "cough_v1",
      field: "isCough",
      sort: "asc",
    },
    {
      label: "duration_cough",
      field: "coughDays",
      sort: "asc",
    },
    {
      label: "shortness_of_breath_v1",
      field: "isShortnessBreath",
      sort: "asc",
    },
    {
      label: "duration_shortness_of_brea",
      field: "shortnessBreathDays",
      sort: "asc",
    },
    {
      label: "fatigue",
      field: "isFatigue",
      sort: "asc",
    },
    {
      label: "duration_fatigue",
      field: "fatigueDays",
      sort: "asc",
    },
    {
      label: "sore_throat",
      field: "isSoreThroat",
      sort: "asc",
    },
    {
      label: "duration_sore_throat",
      field: "soreThroatDays",
      sort: "asc",
    },
    {
      label: "runny_or_stuffy_nose",
      field: "isRunnyNose",
      sort: "asc",
    },
    {
      label: "duration_runny_or_stuffy_n",
      field: "runnyNoseDays",
      sort: "asc",
    },
    {
      label: "muscle_pain_or_bodyaches",
      field: "isMusclePains",
      sort: "asc",
    },
    {
      label: "duration_muscle_or_bodyach",
      field: "musclePainDays",
      sort: "asc",
    },
    {
      label: "headache",
      field: "isHeadache",
      sort: "asc",
    },
    {
      label: "duration_headache",
      field: "headacheDays",
      sort: "asc",
    },
    {
      label: "vomitting_and_diarrhea",
      field: "isVomiting",
      sort: "asc",
    },
    {
      label: "duration_vomitting_and_dia",
      field: "vomitingDays",
      sort: "asc",
    },
    {
      label: "did_you_consult_any_medica",
      field: "isConsultPractitioner",
      sort: "asc",
    },
    {
      label: "which_facility_did_you_vis",
      field: "facilityName",
      sort: "asc",
    },
    {
      label: "consultantTypes",
      field: "consultantTypes",
      sort: "asc",
    },
    {
      label: "did_you_require_any_hospit",
      field: "isHospitalised",
      sort: "asc",
    },
    {
      label: "hospitalisedDays",
      field: "hospitalisedDays",
      sort: "asc",
    },
    {
      label: "did_you_take_any_medicine",
      field: "isTakeMedicine",
      sort: "asc",
    },
    {
      label: "drugTypes",
      field: "drugTypes",
      sort: "asc",
    },
    {
      label: "did_you_get_yourself_teste",
      field: "isCovidTest",
      sort: "asc",
    },
    {
      label: "what_was_the_result",
      field: "covidResult",
      sort: "asc",
    },
    {
      label: "are_you_vaccinated_against",
      field: "isVaccinated",
      sort: "asc",
    },
    {
      label: "mention_the_number_of_dose",
      field: "noOfDoses",
      sort: "asc",
    },
    {
      label: "preExistingSymptoms",
      field: "preExistingSymptoms",
      sort: "asc",
    },
    {
      label: "isPregnant",
      field: "isPregnant",
      sort: "asc",
    },
    {
      label: "trimesterValue",
      field: "trimesterValue",
      sort: "asc",
    },
    {
      label: "did_you_wear_masks_while_g",
      field: "isWearingMask",
      sort: "asc",
    },
    {
      label: "do_you_sanitize_or_wash_yo",
      field: "isSanitizingHands",
      sort: "asc",
    },
    {
      label: "did_you_travel_anywhere_wi",
      field: "isTravelAnywhere",
      sort: "asc",
    },
    {
      label: "did_you_have_to_take_a_day",
      field: "isDayOff",
      sort: "asc",
    },
    {
      label: "how_many_days_in_yes",
      field: "dayOffDays",
      sort: "asc",
    },
    {
      label: "was_your_income_affected_d",
      field: "isIncomeAffected",
      sort: "asc",
    },
    {
      label: "affectedValue",
      field: "affectedValue",
      sort: "asc",
    },
    {
      label: "isTakeSamples",
      field: "isTakeSamples",
      sort: "asc",
    },
    {
      label: "sampleRefusalreason",
      field: "sampleRefusalreason",
      sort: "asc",
    },
    {
      label: "OthersampleRefusalreason",
      field: "othersamplerefuse",
      sort: "asc",
    },
    {
      label: "swabId",
      field: "swabId",
      sort: "asc",
    },
    {
      label: "swabDate",
      field: "swabDate",
      sort: "asc",
    },
  ];

  // Define rows
  const rows = formData?.map((formDataItem, index) => ({
    userId: formDataItem.HW_ID || formDataItem.HW_ID,
    interviewerName:
      formDataItem.FORM_DATA.generalInformation !== undefined
        ? JSON.parse(formDataItem.FORM_DATA.generalInformation)
          .interviewerName === "" ||
          JSON.parse(formDataItem.FORM_DATA.generalInformation)
            .interviewerName === null
          ? filler2
          : JSON.parse(formDataItem.FORM_DATA.generalInformation)
            .interviewerName
        : filler,
    interviewerId:
      formDataItem.FORM_DATA.generalInformation !== undefined
        ? JSON.parse(formDataItem.FORM_DATA.generalInformation)
          .interviewerId === "" ||
          JSON.parse(formDataItem.FORM_DATA.generalInformation)
            .interviewerId === null
          ? filler2
          : JSON.parse(formDataItem.FORM_DATA.generalInformation).interviewerId
        : filler,

    udNumber:
      formDataItem.FORM_DATA.generalInformation !== undefined
        ? JSON.parse(formDataItem.FORM_DATA.generalInformation)
          .udNumber === "" ||
          JSON.parse(formDataItem.FORM_DATA.generalInformation)
            .udNumber === null
          ? filler2
          : JSON.parse(formDataItem.FORM_DATA.generalInformation).udNumber
        : filler,

    psu:
      formDataItem.FORM_DATA.generalInformation !== undefined
        ? JSON.parse(formDataItem.FORM_DATA.generalInformation)
          .psu === "" ||
          JSON.parse(formDataItem.FORM_DATA.generalInformation)
            .psu === null
          ? filler2
          : JSON.parse(formDataItem.FORM_DATA.generalInformation).psu
        : filler,

    district:
      formDataItem.FORM_DATA.generalInformation !== undefined
        ? JSON.parse(formDataItem.FORM_DATA.generalInformation).districtName ===
          "" ||
          JSON.parse(formDataItem.FORM_DATA.generalInformation).districtName ===
          null
          ? filler2
          : JSON.parse(formDataItem.FORM_DATA.generalInformation).districtName
        : filler,
    participantConsent:
      formDataItem.FORM_DATA.generalInformation !== undefined
        ? JSON.parse(formDataItem.FORM_DATA.generalInformation)
          .participantConsent === "" ||
          JSON.parse(formDataItem.FORM_DATA.generalInformation)
            .participantConsent === null
          ? filler2
          : JSON.parse(formDataItem.FORM_DATA.generalInformation)
            .participantConsent
        : filler,
    hidNumber:
      formDataItem.FORM_DATA.generalInformation !== undefined
        ? JSON.parse(formDataItem.FORM_DATA.generalInformation).hhidNumber ===
          "" ||
          JSON.parse(formDataItem.FORM_DATA.generalInformation).hhidNumber ===
          null
          ? filler2
          : JSON.parse(formDataItem.FORM_DATA.generalInformation).hhidNumber
        : filler,


    surveystatus:
      formDataItem.FORM_DATA.generalInformation !== undefined
        ? JSON.parse(formDataItem.FORM_DATA.generalInformation).surveystatus ===
          "" ||
          JSON.parse(formDataItem.FORM_DATA.generalInformation).surveystatus ===
          null
          ? filler2
          : surveyStatusMap[
          JSON.parse(formDataItem.FORM_DATA.generalInformation).surveystatus
          ] || "Unknown Status"
        : filler,

    // surveystatus:
    //   formDataItem.FORM_DATA.generalInformation !== undefined
    //     ? JSON.parse(formDataItem.FORM_DATA.generalInformation).surveystatus ===
    //       "" ||
    //       JSON.parse(formDataItem.FORM_DATA.generalInformation).surveystatus ===
    //       null
    //       ? filler2
    //       : JSON.parse(formDataItem.FORM_DATA.generalInformation).surveystatus
    //     : filler,


    sector:
      formDataItem.FORM_DATA.generalInformation !== undefined
        ? JSON.parse(formDataItem.FORM_DATA.generalInformation).sectorName ===
          "" ||
          JSON.parse(formDataItem.FORM_DATA.generalInformation).sectorName ===
          null
          ? filler2
          : JSON.parse(formDataItem.FORM_DATA.generalInformation).sectorName
        : filler,

    pid:
      formDataItem.FORM_DATA.generalInformation !== undefined
        ? JSON.parse(formDataItem.FORM_DATA.generalInformation).pid === "" ||
          JSON.parse(formDataItem.FORM_DATA.generalInformation).pid === null
          ? filler2
          : JSON.parse(formDataItem.FORM_DATA.generalInformation).pid
        : filler,

    city:
      formDataItem.FORM_DATA.generalInformation !== undefined
        ? JSON.parse(formDataItem.FORM_DATA.generalInformation).city === "" ||
          JSON.parse(formDataItem.FORM_DATA.generalInformation).city === null
          ? filler2
          : JSON.parse(formDataItem.FORM_DATA.generalInformation).city
        : filler,
    householdMembers:
      formDataItem.FORM_DATA.generalInformation !== undefined
        ? JSON.parse(formDataItem.FORM_DATA.generalInformation)
          .householdMembers === "" ||
          JSON.parse(formDataItem.FORM_DATA.generalInformation)
            .householdMembers === null
          ? filler2
          : JSON.parse(formDataItem.FORM_DATA.generalInformation)
            .householdMembers
        : filler,

    chcName:
      formDataItem.FORM_DATA.generalInformation !== undefined
        ? JSON.parse(formDataItem.FORM_DATA.generalInformation).chcName ===
          "" ||
          JSON.parse(formDataItem.FORM_DATA.generalInformation).chcName === null
          ? filler2
          : JSON.parse(formDataItem.FORM_DATA.generalInformation).chcName
        : filler,

    phcName:
      formDataItem.FORM_DATA.generalInformation !== undefined
        ? JSON.parse(formDataItem.FORM_DATA.generalInformation).phcName ===
          "" ||
          JSON.parse(formDataItem.FORM_DATA.generalInformation).phcName === null
          ? filler2
          : JSON.parse(formDataItem.FORM_DATA.generalInformation).phcName
        : filler,

    healthCenterName:
      formDataItem.FORM_DATA.generalInformation !== undefined
        ? JSON.parse(formDataItem.FORM_DATA.generalInformation)
          .healthCenterName === "" ||
          JSON.parse(formDataItem.FORM_DATA.generalInformation)
            .healthCenterName === null
          ? filler2
          : JSON.parse(formDataItem.FORM_DATA.generalInformation)
            .healthCenterName
        : filler,

    date:
      formDataItem.FORM_DATA.generalInformation !== undefined
        ? JSON.parse(formDataItem.FORM_DATA.generalInformation).date === "" ||
          JSON.parse(formDataItem.FORM_DATA.generalInformation).date === null
          ? filler2
          : JSON.parse(formDataItem.FORM_DATA.generalInformation).date
        : filler,

    symptoms:
      formDataItem.FORM_DATA.generalInformation !== undefined
        ? JSON.parse(formDataItem.FORM_DATA.generalInformation).symptoms ===
          "" ||
          JSON.parse(formDataItem.FORM_DATA.generalInformation).symptoms ===
          null ||
          JSON.parse(formDataItem.FORM_DATA.generalInformation)?.symptoms
            .length === 0
          ? filler2
          : JSON.parse(formDataItem.FORM_DATA.generalInformation).symptoms.join(
            ","
          )
        : filler,

    nosymtomsbutton:
      formDataItem.FORM_DATA.generalInformation !== undefined
        ? JSON.parse(formDataItem.FORM_DATA.generalInformation).nosymtomsbutton === "" ||
          JSON.parse(formDataItem.FORM_DATA.generalInformation).nosymtomsbutton === null
          ? filler2
          : JSON.parse(formDataItem.FORM_DATA.generalInformation).nosymtomsbutton
        : filler,

    symptomsMembers:
      formDataItem.FORM_DATA.generalInformation !== undefined
        ? JSON.parse(formDataItem.FORM_DATA.generalInformation)
          .symptomsMembers === "" ||
          JSON.parse(formDataItem.FORM_DATA.generalInformation)
            .symptomsMembers === null
          ? filler2
          : JSON.parse(formDataItem.FORM_DATA.generalInformation)
            .symptomsMembers
        : filler,

    patientName: myFunction(
      formDataItem.FORM_DATA,
      "houseMembersHealth",
      "patientName",
      ","
    ),
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(
    //       formDataItem.FORM_DATA.houseHoldMemberModel[0].houseMembersHealth
    //     ).patientName === ("" || null)
    //     ? filler2
    //     : JSON.parse(
    //         formDataItem.FORM_DATA.houseHoldMemberModel[0].houseMembersHealth
    //       ).patientName
    //   : filler,
    patientAge: myFunction(
      formDataItem.FORM_DATA,
      "houseMembersHealth",
      "patientAge",
      ","
    ),

    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(
    //       formDataItem.FORM_DATA.houseHoldMemberModel[0].houseMembersHealth
    //     ).patientAge === ("" || null)
    //     ? filler2
    //     : JSON.parse(
    //         formDataItem.FORM_DATA.houseHoldMemberModel[0].houseMembersHealth
    //       ).patientAge
    //   : filler,

    gender: myFunction(
      formDataItem.FORM_DATA,
      "houseMembersHealth",
      "gender",
      ","
    ),

    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(
    //       formDataItem.FORM_DATA.houseHoldMemberModel[0].houseMembersHealth
    //     ).gender === ("" || null)
    //     ? filler2
    //     : JSON.parse(
    //         formDataItem.FORM_DATA.houseHoldMemberModel[0].houseMembersHealth
    //       ).gender
    //   : filler,

    maritalStatus: myFunction(
      formDataItem.FORM_DATA,
      "houseMembersHealth",
      "maritalStatus",
      ","
    ),

    othermarital: myFunction(
      formDataItem.FORM_DATA,
      "houseMembersHealth",
      "othermarital",
      ","
    ),

    othereducation: myFunction(
      formDataItem.FORM_DATA,
      "houseMembersHealth",
      "othereducation",
      ","
    ),

    otheroccupation: myFunction(
      formDataItem.FORM_DATA,
      "houseMembersHealth",
      "otheroccupation",
      ","
    ),

    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(
    //       formDataItem.FORM_DATA.houseHoldMemberModel[0].houseMembersHealth
    //     ).maritalStatus === ("" || null)
    //     ? filler2
    //     : JSON.parse(
    //         formDataItem.FORM_DATA.houseHoldMemberModel[0].houseMembersHealth
    //       ).maritalStatus
    //   : filler,

    educationalStatus: myFunction(
      formDataItem.FORM_DATA,
      "houseMembersHealth",
      "educationStatus",
      ","
    ),

    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(
    //       formDataItem.FORM_DATA.houseHoldMemberModel[0].houseMembersHealth
    //     ).educationalStatus === ("" || null)
    //     ? filler2
    //     : JSON.parse(
    //         formDataItem.FORM_DATA.houseHoldMemberModel[0].houseMembersHealth
    //       ).educationalStatus
    //   : filler,

    occupationStatus: myFunction(
      formDataItem.FORM_DATA,
      "houseMembersHealth",
      "occupationStatus",
      ","
    ),

    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(
    //       formDataItem.FORM_DATA.houseHoldMemberModel[0].houseMembersHealth
    //     ).occupationStatus === ("" || null)
    //     ? filler2
    //     : JSON.parse(
    //         formDataItem.FORM_DATA.houseHoldMemberModel[0].houseMembersHealth
    //       ).occupationStatus
    //   : filler,

    tobaccoStatus: myFunction(
      formDataItem.FORM_DATA,
      "houseMembersHealth",
      "tobaccoStatus",
      ","
    ),

    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(
    //       formDataItem.FORM_DATA.houseHoldMemberModel[0].houseMembersHealth
    //     ).tobaccoStatus === ("" || null)
    //     ? filler2
    //     : JSON.parse(
    //         formDataItem.FORM_DATA.houseHoldMemberModel[0].houseMembersHealth
    //       ).tobaccoStatus
    //   : filler,

    alcoholStatus: myFunction(
      formDataItem.FORM_DATA,
      "houseMembersHealth",
      "alcoholStatus",
      ","
    ),

    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(
    //       formDataItem.FORM_DATA.houseHoldMemberModel[0].houseMembersHealth
    //     ).isDrinkingAlcohol === ("" || null)
    //     ? filler2
    //     : JSON.parse(
    //         formDataItem.FORM_DATA.houseHoldMemberModel[0].houseMembersHealth
    //       ).isDrinkingAlcohol
    //   : filler,

    patientWaistInCm: myFunction(
      formDataItem.FORM_DATA,
      "houseMembersHealth",
      "patientWaistInCm",
      ","
    ),

    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(
    //       formDataItem.FORM_DATA.houseHoldMemberModel[0].houseMembersHealth
    //     ).patientWaistInCm === ("" || null)
    //     ? filler2
    //     : JSON.parse(
    //         formDataItem.FORM_DATA.houseHoldMemberModel[0].houseMembersHealth
    //       ).patientWaistInCm
    //   : filler,
    patientHeightInCm: myFunction(
      formDataItem.FORM_DATA,
      "houseMembersHealth",
      "patientHeightInCm",
      ","
    ),

    patientWeightInKg: myFunction(
      formDataItem.FORM_DATA,
      "houseMembersHealth",
      "patientWeightInKg",
      ","
    ),

    patientTemperatureInC: myFunction(
      formDataItem.FORM_DATA,
      "houseMembersHealth",
      "patientTemperatureInC",
      ","
    ),

    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(
    //       formDataItem.FORM_DATA.houseHoldMemberModel[0].houseMembersHealth
    //     ).patientHeightInCm === ("" || null)
    //     ? filler2
    //     : JSON.parse(
    //         formDataItem.FORM_DATA.houseHoldMemberModel[0].houseMembersHealth
    //       ).patientHeightInCm
    //   : filler,

    // patientWeightInKg:
    //   formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    //   formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    //   formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //     ? JSON.parse(
    //         formDataItem.FORM_DATA.houseHoldMemberModel[0].houseMembersHealth
    //       ).patientWeightInKg === ("" || null)
    //       ? filler2
    //       : JSON.parse(
    //           formDataItem.FORM_DATA.houseHoldMemberModel[0].houseMembersHealth
    //         ).patientWeightInKg
    //     : filler,

    // isFever:
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.houseHoldMemberModel[0].currentHealth).isFever ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.houseHoldMemberModel[0].currentHealth).isFever
    //   : filler,
    isFever: myFunction1(
      formDataItem.FORM_DATA,
      "currentHealth",
      "isFever",
      ","
    ),


    feverDays: myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "feverDays",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).feverDays ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).feverDays
    //   : filler,

    isMyalgia: myFunction1(
      formDataItem.FORM_DATA,
      "currentHealth",
      "isMyalgia",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).isMyalgia ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).isMyalgia
    //   : filler,

    myalgiaDays: myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "myalgiaDays",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).myalgiaDays ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).myalgiaDays
    //   : filler,

    isCough: myFunction1(
      formDataItem.FORM_DATA,
      "currentHealth",
      "isCough",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).isCough ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).isCough
    //   : filler,

    coughDays: myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "coughDays",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).coughDays ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).coughDays
    //   : filler,

    isShortnessBreath: myFunction1(
      formDataItem.FORM_DATA,
      "currentHealth",
      "isShortnessBreath",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).isShortnessBreath ===
    //     ""
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).isShortnessBreath
    //   : filler,

    shortnessBreathDays: myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "shortnessBreathDays",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth)
    //       .shortnessBreathDays === ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).shortnessBreathDays
    //   : filler,

    isShortnessBreathDays: myFunction1(
      formDataItem.FORM_DATA,
      "currentHealth",
      "isShortnessBreathDays",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth)
    //       .isShortnessBreathDays === ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth)
    //         .isShortnessBreathDays
    //   : filler,

    isFatigue: myFunction1(
      formDataItem.FORM_DATA,
      "currentHealth",
      "isFatigue",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).isFatigue ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).isFatigue
    //   : filler,

    fatigueDays: myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "fatigueDays",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).fatigueDays ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).fatigueDays
    //   : filler,

    isSoreThroat: myFunction1(
      formDataItem.FORM_DATA,
      "currentHealth",
      "isSoreThroat",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).isSoreThroat ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).isSoreThroat
    //   : filler,

    soreThroatDays: myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "soreThroatDays",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).soreThroatDays ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).soreThroatDays
    //   : filler,

    isRunnyNose: myFunction1(
      formDataItem.FORM_DATA,
      "currentHealth",
      "isRunnyNose",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).isRunnyNose ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).isRunnyNose
    //   : filler,

    runnyNoseDays: myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "runnyNoseDays",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).runnyNoseDays ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).runnyNoseDays
    //   : filler,

    isMusclePains: myFunction1(
      formDataItem.FORM_DATA,
      "currentHealth",
      "isMusclePains",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).isMusclePains ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).isMusclePains
    //   : filler,

    musclePainDays: myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "musclePainDays",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).musclePainDays ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).musclePainDays
    //   : filler,

    isHeadache: myFunction1(
      formDataItem.FORM_DATA,
      "currentHealth",
      "isHeadache",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).isHeadache ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).isHeadache
    //   : filler,

    headacheDays: myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "headacheDays",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).headacheDays ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).headacheDays
    //   : filler,

    isVomiting: myFunction1(
      formDataItem.FORM_DATA,
      "currentHealth",
      "isVomiting",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).isVomiting ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).isVomiting
    //   : filler,

    vomitingDays: myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "vomitingDays",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).vomitingDays ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).vomitingDays
    //   : filler,
    isConsultPractitioner: myFunction1(
      formDataItem.FORM_DATA,
      "currentHealth",
      "isConsultPractitioner",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth)
    //       .isConsultPractitioner === ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth)
    //         .isConsultPractitioner
    //   : filler,

    facilityName: myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "facilityName",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).facilityName ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).facilityName
    //   : filler,

    // consultantTypes:  formDataItem.FORM_DATA.houseHoldMemberModel !== undefined
    // ? JSON.parse(formDataItem.FORM_DATA.houseHoldMemberModel) ===
    //   "" ||
    //   JSON.parse(formDataItem.FORM_DATA.houseHoldMemberModel) ===
    //   null
    //   ? filler2
    //   : consult[
    //   JSON.parse(formDataItem.FORM_DATA.houseHoldMemberModel)
    //   ] || "Unknown Status"
    // : filler,
    consultantTypes:
      myFunctionlistnubertodata(
        formDataItem.FORM_DATA,
        "currentHealth",
        "consultantTypes",
        consult,
        ","
      ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).consultantTypes ===
    //     ""
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).consultantTypes
    //   : filler,

    isHospitalised: myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "isHospitalised",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).isHospitalised ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).isHospitalised
    //   : filler,

    hospitalisedDays: myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "hospitalisedDays",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).hospitalisedDays ===
    //     ""
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).hospitalisedDays
    //   : filler,

    isTakeMedicine: myFunction1(
      formDataItem.FORM_DATA,
      "currentHealth",
      "isTakeMedicine",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).isTakeMedicine ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).isTakeMedicine
    //   : filler,

    drugTypes: myFunctionlistnubertodata(
      formDataItem.FORM_DATA,
      "currentHealth",
      "drugTypes",
      drugtype,
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).drugTypes ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).drugTypes
    //   : filler,

    isCovidTest: myFunction1(
      formDataItem.FORM_DATA,
      "currentHealth",
      "isCovidTest",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).isCovidTest ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).isCovidTest
    //   : filler,

    covidResult: myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "covidResult",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).covidResult ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).covidResult
    //   : filler,

    isVaccinated: myFunction1(
      formDataItem.FORM_DATA,
      "currentHealth",
      "isVaccinated",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).isVaccinated ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).isVaccinated
    //   : filler,

    noOfDoses: myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "noOfDoses",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).noOfDoses ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).noOfDoses
    //   : filler,

    preExistingSymptoms: myFunctionlistnubertodata(
      formDataItem.FORM_DATA,
      "currentHealth",
      "preExistingSymptoms",
      complaint,
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth)
    //       .preExistingSymptoms === ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).preExistingSymptoms
    //   : filler,

    isPregnant: myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "isPregnant",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).isPregnant ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).isPregnant
    //   : filler,
    trimesterValue: myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "trimesterValue",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).trimesterValue ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).trimesterValue
    //   : filler,

    isWearingMask: myFunction(
      formDataItem.FORM_DATA,
      "generalRespondents",
      "isWearingMask",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).isWearingMask ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).isWearingMask
    //   : filler,

    isSanitizingHands: myFunction(
      formDataItem.FORM_DATA,
      "generalRespondents",
      "isSanitizingHands",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).isSanitizingHands ===
    //     ""
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).isSanitizingHands
    //   : filler,

    isTravelAnywhere: myFunction(
      formDataItem.FORM_DATA,
      "generalRespondents",
      "isTravelAnywhere",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).isTravelAnywhere ===
    //     ""
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).isTravelAnywhere
    //   : filler,

    isDayOff: myFunction(
      formDataItem.FORM_DATA,
      "generalRespondents",
      "isDayOff",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).isDayOff ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).isDayOff
    //   : filler,

    dayOffDays: myFunction(
      formDataItem.FORM_DATA,
      "generalRespondents",
      "dayOffDays",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).dayOffDays ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).dayOffDays
    //   : filler,

    isIncomeAffected: myFunction(
      formDataItem.FORM_DATA,
      "generalRespondents",
      "isIncomeAffected",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).isIncomeAffected ===
    //     ""
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).isIncomeAffected
    //   : filler,

    affectedValue: myFunction(
      formDataItem.FORM_DATA,
      "generalRespondents",
      "affectedValue",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).affectedValue ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).affectedValue
    //   : filler,

    isTakeSamples: myFunction(
      formDataItem.FORM_DATA,
      "generalRespondents",
      "isTakeSamples",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).isTakeSamples ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).isTakeSamples
    //   : filler,

    sampleRefusalreason: myFunctionlisttexttodata(
      formDataItem.FORM_DATA,
      "generalRespondents",
      "sampleRefusalreason",
      refuse,
      ","
    ),

    othersamplerefuse: myFunctionlisttexttodata(
      formDataItem.FORM_DATA,
      "generalRespondents",
      "othersamplerefuse",
      refuse,
      ","
    ),


    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth)
    //       .sampleRefusalreason === ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).sampleRefusalreason
    //   : filler,

    swabId: myFunction(
      formDataItem.FORM_DATA,
      "generalRespondents",
      "swabId",
      ","
    ),

    swabDate: myFunction(
      formDataItem.FORM_DATA,
      "generalRespondents",
      "swabDate",
      ","
    ),
  }));

  // const downloadrows = formData.map((formDataItem, index) => [
  //   formDataItem.HW_ID || formDataItem.HW_ID,

  //   formDataItem.FORM_DATA.generalInformation !== undefined
  //     ? JSON.parse(formDataItem.FORM_DATA.generalInformation)
  //         .interviewerName === "" ||
  //       JSON.parse(formDataItem.FORM_DATA.generalInformation)
  //         .interviewerName === null
  //       ? filler2
  //       : JSON.parse(formDataItem.FORM_DATA.generalInformation).interviewerName
  //     : filler,

  //   formDataItem.FORM_DATA.generalInformation !== undefined
  //     ? JSON.parse(formDataItem.FORM_DATA.generalInformation).interviewerId ===
  //         "" ||
  //       JSON.parse(formDataItem.FORM_DATA.generalInformation).interviewerId ===
  //         null
  //       ? filler2
  //       : JSON.parse(formDataItem.FORM_DATA.generalInformation).interviewerId
  //     : filler,
  //   formDataItem.FORM_DATA.generalInformation !== undefined
  //     ? JSON.parse(formDataItem.FORM_DATA.generalInformation).districtName ===
  //         "" ||
  //       JSON.parse(formDataItem.FORM_DATA.generalInformation).districtName ===
  //         null
  //       ? filler2
  //       : JSON.parse(formDataItem.FORM_DATA.generalInformation).districtName
  //     : filler,

  //   formDataItem.FORM_DATA.generalInformation !== undefined
  //     ? JSON.parse(formDataItem.FORM_DATA.generalInformation)
  //         .participantConsent === "" ||
  //       JSON.parse(formDataItem.FORM_DATA.generalInformation)
  //         .participantConsent === null
  //       ? filler2
  //       : JSON.parse(formDataItem.FORM_DATA.generalInformation)
  //           .participantConsent
  //     : filler,

  //   formDataItem.FORM_DATA.generalInformation !== undefined
  //     ? JSON.parse(formDataItem.FORM_DATA.generalInformation).hhidNumber ===
  //         "" ||
  //       JSON.parse(formDataItem.FORM_DATA.generalInformation).hhidNumber ===
  //         null
  //       ? filler2
  //       : JSON.parse(formDataItem.FORM_DATA.generalInformation).hhidNumber
  //     : filler,

  //   // formDataItem.FORM_DATA.generalInformation !== undefined
  //   //   ? JSON.parse(formDataItem.FORM_DATA.generalInformation).blockName ===
  //   //       "" ||
  //   //     JSON.parse(formDataItem.FORM_DATA.generalInformation).blockName === null
  //   //     ? filler2
  //   //     : JSON.parse(formDataItem.FORM_DATA.generalInformation).blockName
  //   //   : filler,

  //   formDataItem.FORM_DATA.generalInformation !== undefined
  //     ? JSON.parse(formDataItem.FORM_DATA.generalInformation).sectorName ===
  //         "" ||
  //       JSON.parse(formDataItem.FORM_DATA.generalInformation).sectorName ===
  //         null
  //       ? filler2
  //       : JSON.parse(formDataItem.FORM_DATA.generalInformation).sectorName
  //     : filler,

  //   formDataItem.FORM_DATA.generalInformation !== undefined
  //     ? JSON.parse(formDataItem.FORM_DATA.generalInformation).pid === "" ||
  //       JSON.parse(formDataItem.FORM_DATA.generalInformation).pid === null
  //       ? filler2
  //       : JSON.parse(formDataItem.FORM_DATA.generalInformation).pid
  //     : filler,

  //   formDataItem.FORM_DATA.generalInformation !== undefined
  //     ? JSON.parse(formDataItem.FORM_DATA.generalInformation).city === "" ||
  //       JSON.parse(formDataItem.FORM_DATA.generalInformation).city === null
  //       ? filler2
  //       : JSON.parse(formDataItem.FORM_DATA.generalInformation).city
  //     : filler,

  //   // formDataItem.FORM_DATA.generalInformation !== undefined
  //   //   ? JSON.parse(formDataItem.FORM_DATA.generalInformation)
  //   //       .neighbourhoodName === "" ||
  //   //     JSON.parse(formDataItem.FORM_DATA.generalInformation)
  //   //       .neighbourhoodName === null
  //   //     ? filler2
  //   //     : JSON.parse(formDataItem.FORM_DATA.generalInformation)
  //   //         .neighbourhoodName
  //   //   : filler,

  //   // formDataItem.FORM_DATA.generalInformation !== undefined
  //   //   ? JSON.parse(formDataItem.FORM_DATA.generalInformation)
  //   //       .householdNumber === "" ||
  //   //     JSON.parse(formDataItem.FORM_DATA.generalInformation)
  //   //       .householdNumber === null
  //   //     ? filler2
  //   //     : JSON.parse(formDataItem.FORM_DATA.generalInformation).householdNumber
  //   //   : filler,

  //   formDataItem?.FORM_DATA?.generalInformation !== undefined
  //     ? JSON.parse(formDataItem.FORM_DATA.generalInformation)
  //         ?.householdMembers === "" ||
  //       JSON.parse(formDataItem.FORM_DATA.generalInformation)
  //         ?.householdMembers === null
  //       ? filler2
  //       : JSON.parse(formDataItem.FORM_DATA.generalInformation)
  //           ?.householdMembers
  //     : filler,

  //   formDataItem.FORM_DATA.generalInformation !== undefined
  //     ? JSON.parse(formDataItem.FORM_DATA.generalInformation).chcName === "" ||
  //       JSON.parse(formDataItem.FORM_DATA.generalInformation).chcName === null
  //       ? filler2
  //       : JSON.parse(formDataItem.FORM_DATA.generalInformation).chcName
  //     : filler,

  //   formDataItem.FORM_DATA.generalInformation !== undefined
  //     ? JSON.parse(formDataItem.FORM_DATA.generalInformation).phcName === "" ||
  //       JSON.parse(formDataItem.FORM_DATA.generalInformation).phcName === null
  //       ? filler2
  //       : JSON.parse(formDataItem.FORM_DATA.generalInformation).phcName
  //     : filler,

  //   formDataItem.FORM_DATA.generalInformation !== undefined
  //     ? JSON.parse(formDataItem.FORM_DATA.generalInformation)
  //         .healthCenterName === "" ||
  //       JSON.parse(formDataItem.FORM_DATA.generalInformation)
  //         .healthCenterName === null
  //       ? filler2
  //       : JSON.parse(formDataItem.FORM_DATA.generalInformation).healthCenterName
  //     : filler,

  //   formDataItem.FORM_DATA.generalInformation !== undefined
  //     ? JSON.parse(formDataItem.FORM_DATA.generalInformation).date === "" ||
  //       JSON.parse(formDataItem.FORM_DATA.generalInformation).date === null
  //       ? filler2
  //       : JSON.parse(formDataItem.FORM_DATA.generalInformation).date
  //     : filler,

  //   formDataItem.FORM_DATA.generalInformation !== undefined
  //     ? JSON.parse(formDataItem.FORM_DATA.generalInformation).symptoms === "" ||
  //       JSON.parse(formDataItem.FORM_DATA.generalInformation).symptoms ===
  //         null ||
  //       JSON.parse(formDataItem.FORM_DATA.generalInformation).symptoms
  //         .length === 0
  //       ? filler2
  //       : JSON.parse(formDataItem.FORM_DATA.generalInformation).symptoms.join(
  //           ", "
  //         )
  //     : filler,

  //   formDataItem.FORM_DATA.generalInformation !== undefined
  //     ? JSON.parse(formDataItem.FORM_DATA.generalInformation)
  //         .symptomsMembers === "" ||
  //       JSON.parse(formDataItem.FORM_DATA.generalInformation)
  //         .symptomsMembers === null
  //       ? filler2
  //       : JSON.parse(formDataItem.FORM_DATA.generalInformation).symptomsMembers
  //     : filler,

  //   myFunction(formDataItem.FORM_DATA, "houseMembersHealth", "patientName"),
  //   myFunction(formDataItem.FORM_DATA, "houseMembersHealth", "patientAge"),
  //   myFunction(formDataItem.FORM_DATA, "houseMembersHealth", "gender"),
  //   myFunction(formDataItem.FORM_DATA, "houseMembersHealth", "maritalStatus"),
  //   myFunction(formDataItem.FORM_DATA, "houseMembersHealth", "educationStatus"),
  //   myFunction(
  //     formDataItem.FORM_DATA,
  //     "houseMembersHealth",
  //     "occupationStatus"
  //   ),
  //   myFunction(formDataItem.FORM_DATA, "houseMembersHealth", "tobaccoStatus"),
  //   myFunction(
  //     formDataItem.FORM_DATA,
  //     "houseMembersHealth",
  //     "isDrinkingAlcohol"
  //   ),
  //   myFunction(
  //     formDataItem.FORM_DATA,
  //     "houseMembersHealth",
  //     "patientWaistInCm"
  //   ),
  //   myFunction(
  //     formDataItem.FORM_DATA,
  //     "houseMembersHealth",
  //     "patientHeightInCm"
  //   ),

  //   myFunction(formDataItem.FORM_DATA, "currentHealth", "isFever"),

  //   myFunction(formDataItem.FORM_DATA, "currentHealth", "feverDays"),
  //   myFunction(formDataItem.FORM_DATA, "currentHealth", "isMyalgia"),
  //   myFunction(formDataItem.FORM_DATA, "currentHealth", "myalgiaDays"),
  //   myFunction(formDataItem.FORM_DATA, "currentHealth", "isCough"),
  //   myFunction(formDataItem.FORM_DATA, "currentHealth", "coughDays"),
  //   myFunction(formDataItem.FORM_DATA, "currentHealth", "isShortnessBreath"),
  //   myFunction(formDataItem.FORM_DATA, "currentHealth", "shortnessBreathDays"),
  //   myFunction(
  //     formDataItem.FORM_DATA,
  //     "currentHealth",
  //     "isShortnessBreathDays"
  //   ),
  //   myFunction(formDataItem.FORM_DATA, "currentHealth", "isFatigue"),
  //   myFunction(formDataItem.FORM_DATA, "currentHealth", "fatigueDays"),
  //   myFunction(formDataItem.FORM_DATA, "currentHealth", "isSoreThroat"),
  //   myFunction(formDataItem.FORM_DATA, "currentHealth", "soreThroatDays"),
  //   myFunction(formDataItem.FORM_DATA, "currentHealth", "isRunnyNose"),
  //   myFunction(formDataItem.FORM_DATA, "currentHealth", "runnyNoseDays"),
  //   myFunction(formDataItem.FORM_DATA, "currentHealth", "isMusclePains"),
  //   myFunction(formDataItem.FORM_DATA, "currentHealth", "musclePainDays"),
  //   myFunction(formDataItem.FORM_DATA, "currentHealth", "isHeadache"),
  //   myFunction(formDataItem.FORM_DATA, "currentHealth", "headacheDays"),
  //   myFunction(formDataItem.FORM_DATA, "currentHealth", "isVomiting"),
  //   myFunction(formDataItem.FORM_DATA, "currentHealth", "vomitingDays"),
  //   myFunction(
  //     formDataItem.FORM_DATA,
  //     "currentHealth",
  //     "isConsultPractitioner"
  //   ),
  //   myFunction(formDataItem.FORM_DATA, "currentHealth", "facilityName"),
  //   myFunction(formDataItem.FORM_DATA, "currentHealth", "consultantTypes"),
  //   myFunction(formDataItem.FORM_DATA, "currentHealth", "isHospitalised"),
  //   myFunction(formDataItem.FORM_DATA, "currentHealth", "hospitalisedDays"),
  //   myFunction(formDataItem.FORM_DATA, "currentHealth", "isTakeMedicine"),
  //   myFunction(formDataItem.FORM_DATA, "currentHealth", "drugTypes"),
  //   myFunction(formDataItem.FORM_DATA, "currentHealth", "isCovidTest"),
  //   myFunction(formDataItem.FORM_DATA, "currentHealth", "covidResult"),
  //   myFunction(formDataItem.FORM_DATA, "currentHealth", "isVaccinated"),
  //   myFunction(formDataItem.FORM_DATA, "currentHealth", "noOfDoses"),
  //   myFunction(formDataItem.FORM_DATA, "currentHealth", "preExistingSymptoms"),
  //   myFunction(formDataItem.FORM_DATA, "currentHealth", "isPregnant"),
  //   myFunction(formDataItem.FORM_DATA, "currentHealth", "trimesterValue"),
  //   myFunction(formDataItem.FORM_DATA, "generalRespondents", "isWearingMask"),
  //   myFunction(
  //     formDataItem.FORM_DATA,
  //     "generalRespondents",
  //     "isSanitizingHands"
  //   ),
  //   myFunction(
  //     formDataItem.FORM_DATA,
  //     "generalRespondents",
  //     "isTravelAnywhere"
  //   ),
  //   myFunction(formDataItem.FORM_DATA, "generalRespondents", "isDayOff"),
  //   myFunction(formDataItem.FORM_DATA, "generalRespondents", "dayOffDays"),
  //   myFunction(
  //     formDataItem.FORM_DATA,
  //     "generalRespondents",
  //     "isIncomeAffected"
  //   ),
  //   myFunction(formDataItem.FORM_DATA, "generalRespondents", "affectedValue"),
  //   myFunction(formDataItem.FORM_DATA, "generalRespondents", "isTakeSamples"),

  //   myFunction(
  //     formDataItem.FORM_DATA,
  //     "generalRespondents",
  //     "sampleRefusalreason"
  //   ),

  //   myFunction(formDataItem.FORM_DATA, "generalRespondents", "swabId"),
  //   myFunction(formDataItem.FORM_DATA, "generalRespondents", "swabDate"),
  // ]);
  const downloadrows = rows


  // const labelNames = [
  //   "User ID",
  //   "Interviewer Name",
  //   "Interviewer ID",
  //   "District",
  //   "Participant Consent",
  //   "HID Number",
  //   // "Block Name",
  //   "Sector",
  //   "Pid",
  //   "City",
  //   // "Neighbourhood",
  //   // "HouseHold Number",
  //   "HouseHold Members",
  //   "CHC Name",
  //   "PHC Name",
  //   "Health Center Name",
  //   "Date",
  //   "Symptoms",
  //   "Symptoms Members",
  //   "PatientName",
  //   "Patient Age",
  //   "Gender",
  //   "MaritalStatus",
  //   "EducationStatus",
  //   "OccupationStatus",
  //   "TobaccoStatus",
  //   "IsDrinkingAlcohol",
  //   "PatientWaistInCm",
  //   "PatientHeightInCm",
  //   "isFever",
  //   "feverDays",
  //   "isMyalgia",
  //   "myalgiaDays",
  //   "coughDays",
  //   "isShortnessBreath",
  //   "shortnessBreathDays",
  //   "isFatigue",
  //   "fatigueDays",
  //   "isSoreThroat",
  //   "soreThroatDays",
  //   "isRunnyNose",
  //   "runnyNoseDays",
  //   "isMusclePains",
  //   "musclePainDays",
  //   "isHeadache",
  //   "headacheDays",
  //   "isVomiting",
  //   "vomitingDays",
  //   "isConsultPractitioner",
  //   "facilityName",
  //   "consultantTypes",
  //   "isHospitalised",
  //   "hospitalisedDays",
  //   "isTakeMedicine",
  //   "drugTypes",
  //   "isCovidTest",
  //   "covidResult",
  //   "isVaccinated",
  //   "noOfDoses",
  //   "preExistingSymptoms",
  //   "isPregnant",
  //   "trimesterValue",
  //   "isWearingMask",
  //   "isSanitizingHands",
  //   "isTravelAnywhere",
  //   "isDayOff",
  //   "dayOffDays",
  //   "isIncomeAffected",
  //   "affectedValue",
  //   "isTakeSamples",
  //   "sampleRefusalreason",
  //   "swabId",
  //   "swabDate",
  // ];
  const labelNames = columns;
  const csvData = [labelNames, ...downloadrows];

  // const datapasscsv = [
  //   {
  //     userID: 1,
  //     mobile: "9876543210",
  //     generalInformation: {
  //       interviewerName: "Sachin Tendulkar",
  //       interviewerId: "123",
  //     },
  //     houseHoldMemberModel: [
  //       {
  //         houseMembersHealth: { patientName: "Madhu", patientAge: 22 },
  //         currentHealth: { isFever: 1, feverDays: 1, isMyalgia: 1 },
  //         generalRespondents: { isWearingMask: 2, isSanitizingHands: 2 },
  //       },
  //       {
  //         houseMembersHealth: { patientName: "raju", patientAge: 22 },
  //         currentHealth: { isFever: 2, feverDays: 6, isMyalgia: 4 },
  //         generalRespondents: { isWearingMask: 1, isSanitizingHands: 9 },
  //       },
  //     ],
  //   },
  //   {
  //     userID: 2,
  //     mobile: "9876543210",
  //     generalInformation: {
  //       interviewerName: "Sachin Tendulkar",
  //       interviewerId: "123",
  //     },
  //     houseHoldMemberModel: [
  //       {
  //         houseMembersHealth: { patientName: "rani", patientAge: 22 },
  //         currentHealth: {
  //           isFever: "null",
  //           feverDays: "null",
  //           isMyalgia: "null",
  //         },
  //         generalRespondents: { isWearingMask: 8, isSanitizingHands: 7 },
  //       },
  //     ],
  //   },
  //   {
  //     userID: 1,
  //     mobile: "9876543210",
  //     generalInformation: {
  //       interviewerName: "Sachin Tendulkar",
  //       interviewerId: "123",
  //     },
  //     houseHoldMemberModel: [
  //       {
  //         houseMembersHealth: { patientName: "Madhu", patientAge: 22 },
  //         currentHealth: { isFever: 77, feverDays: 55, isMyalgia: 85 },
  //         generalRespondents: { isWearingMask: 88, isSanitizingHands: 88 },
  //       },
  //       {
  //         houseMembersHealth: { patientName: "raju", patientAge: 22 },
  //         currentHealth: { isFever: 87, feverDays: 8, isMyalgia: 88 },
  //         generalRespondents: { isWearingMask: 33, isSanitizingHands: 77 },
  //       },
  //     ],
  //   },
  // ];

  return (
    <div>
      <div style={{ display: "flex" }} className="testpagemain">
        <h3 className="pageheading">Household Form Data </h3>
        <div className="testpage">
          <label htmlFor="startDate" style={{ margin: 0 }}>
            Start Date:{" "}
          </label>
          <input
            type="date"
            id="startDate"
            value={startDate ? startDate.toISOString().split("T")[0] : ""}
            onChange={(e) => setStartDate(new Date(e.target.value))}
          />
          <label
            htmlFor="endDate"
            style={{ marginLeft: "20px", marginBottom: 0 }}
          >
            End Date:
          </label>
          <input
            type="date"
            id="endDate"
            value={endDate ? endDate.toISOString().split("T")[0] : ""}
            onChange={(e) => setEndDate(new Date(e.target.value))}
          />
          <button
            style={{ marginLeft: "20px" }}
            onClick={handleFilterByDateRange}
            disabled={isApplyButtonDisabled}
          >
            Apply Date Range
          </button>
        </div>
      </div>

      {/* <DownloadTableExcel
        filename="users table"
        sheet="users"
        currentTableRef={tableRef.current}
      /> */}
      <MDBDataTable
        className="table-container"
        striped
        bordered
        small
        caption="Household Data"
        data={{ columns, rows }}
        // exportToCSV={download}
        noBottomColumns={true}
      />
      {/* <CSVLink
        data={csvData}
        filename={"household-data.csv"}
        className="btn btn-primary mb-4"
      >
        Export to CSV
      </CSVLink> */}
      {console.log(formData, ".......")}
      {download && (
        <DownloadCSVButton
          data={formData}
          date={`${endDate} - ${startDate}`}
          startingDate={startDate}
          endingDate={endDate}
          col={columns}
          rows={rows}
        />
      )}
    </div>
  );
};

export default Test;
