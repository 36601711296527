import React from "react";

// Function to flatten an object
function flattenObject(obj, prefix = "") {
  return Object.keys(obj).reduce((acc, key) => {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      return { ...acc, ...flattenObject(obj[key], `${prefix}${key}_`) };
    } else {
      return { ...acc, [`${prefix}${key}`]: obj[key] };
    }
  }, {});
}

// Function to parse JSON if possible
function tryParseJSON(value) {
  if (value === null || value === "") {
    return 777;
  } else if (value === undefined) {
    return 999;
  } else if (typeof value === "string") {
    try {
      const parsedValue = JSON.parse(value);
      if (typeof parsedValue === "object" && parsedValue !== null) {
        return parseAllJSON(parsedValue);
      }
    } catch (e) {
      return value;
    }
  } else if (typeof value === "object" && value !== null) {
    return parseAllJSON(value);
  }
  return value;
}

// Function to recursively parse all values in an object
function parseAllJSON(obj) {
  if (Array.isArray(obj)) {
    return obj.map((item) => tryParseJSON(item));
  } else if (typeof obj === "object" && obj !== null) {
    return Object.keys(obj).reduce((acc, key) => {
      acc[key] = tryParseJSON(obj[key]);
      return acc;
    }, {});
  }
  return obj;
}

// Function to quote CSV values properly, especially to handle dates and other text containing commas
function quoteCSVValue(value) {
  if (typeof value === "string" && value.includes(",")) {
    return `"${value}"`;
  }
  return value;
}

const DownloadCSVButton = ({ data, date, startingDate, endingDate, col,rows }) => {
  let jsondata = [];

  data.map((item) => {
    jsondata.push(item.FORM_DATA);
  });
  // console.log(jsondata,date);

  function formatDateRange(startDate, endDate) {
    const months = [
      "jan",
      "feb",
      "mar",
      "apr",
      "may",
      "jun",
      "jul",
      "aug",
      "sep",
      "oct",
      "nov",
      "dec",
    ];

    function formatSingleDate(date) {
      const day = date.getDate();
      const month = months[date.getMonth()];
      const year = date.getFullYear();
      return `${day}_${month}_${year}`;
    }

    const start = formatSingleDate(startDate);
    const end = formatSingleDate(endDate);

    return `${start}_to_${end}`;
  }
  // const handleDownload = () => {
  //   // Recursively parse all JSON strings in the data
  //   const parsedData = jsondata.map((entry) => parseAllJSON(entry));

  //   // Flatten the JSON data
  //   const flatData = parsedData.map((entry) => flattenObject(entry));

  //   // Extract column names
  //   const fieldNames =col

  //   const csvData = [Array.from(fieldNames)];
  //   flatData.forEach((entry) => {
  //     const row = [];
  //     fieldNames.forEach((field) => {
  //       row.push(entry[field] !== undefined ? quoteCSVValue(entry[field]) : "");
  //     });
  //     csvData.push(row);
  //   });

  //   const csvString = csvData.map((row) => row.join(",")).join("\n");

  //   // Create a Blob from the CSV string with UTF-8 encoding
  //   const blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csvString], {
  //     type: "text/csv;charset=utf-8",
  //   });

  //   // Create a URL for the Blob
  //   const url = URL.createObjectURL(blob);

  //   // Create a link element and trigger a download
  //   const a = document.createElement("a");
  //   a.href = url;
  //   a.download = `${formatDateRange(startingDate,endingDate)}.csv`;
  //   document.body.appendChild(a);
  //   a.click();
  //   document.body.removeChild(a);
  // };
  const handleDownload = () => {
    // Recursively parse all JSON strings in the data
    const parsedData = rows.map((entry) => parseAllJSON(entry));

    // Flatten the JSON data
    const flatData = parsedData.map((entry) => flattenObject(entry));

    // Extract column field names from the 'col' array
    const fieldNames = col.map((column) => column.field);

    // Create the CSV header based on column 'label'
    const csvData = [col.map((column) => column.label)];

    // Iterate through each data entry and extract values based on field names
    flatData.forEach((entry) => {
      const row = [];
      fieldNames.forEach((field) => {
        row.push(entry[field] !== undefined ? quoteCSVValue(entry[field]) : "");
      });
      csvData.push(row);
    });

    // Join the data to form the CSV string
    const csvString = csvData.map((row) => row.join(",")).join("\n");

    // Create a Blob from the CSV string with UTF-8 encoding
    const blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csvString], {
      type: "text/csv;charset=utf-8",
    });

    // Check if startingDate or endingDate is null or empty
    const fileName =
      startingDate && endingDate
        ? `${formatDateRange(startingDate, endingDate)}.csv`
        : "completedata.csv";

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a link element and trigger a download
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <button
      onClick={handleDownload}
      style={{
        background: "#4b4bf1",
        color: "white",
        border: "none",
        boxShadow: "0px 1px 2px 1px #726d6d",
        padding: "9px 20px",
      }}
    >
      Download
    </button>
  );
};

export default DownloadCSVButton;
